import React from 'react'
import styled from '@emotion/styled'

// -------------------------------------------------
// about, archiveなど各ページのタイトル
// -------------------------------------------------


const Title = styled.h1`
  font-size: 1em;
  text-transform: capitalize;
  font-weight: 600;
  text-align: left;
  margin: ${props => props.margin ? props.margin : "0 0 1.5rem 0"};
  line-height: 1.2;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const PageTitle = props => {
  return <Title small={props.small} margin={props.margin}>{props.children}</Title>
}

export default PageTitle
